<template>
	<el-dialog title="评价结果" :visible.sync="createdVisible" width="520px" center :close-on-click-modal="false">
		<div class="resultList" v-if="evaluationList.length > 0">
			<div class="resultList-item clearfix" v-for="item in evaluationList" :key="item.id">
				<img
					v-if="item.creator.attachmentMap && item.creator.attachmentMap['PHOTO'].length > 0"
					class="float-l"
					:src="item.creator.attachmentMap && item.creator.attachmentMap['PHOTO'].length > 0 && item.creator.attachmentMap['PHOTO'][0].link"
					alt=""
				/>
				<img v-else class="float-l" src="@/assets/imgs/avactor2.png" alt="" />
				<div class="resultList-item-content float-l">
					<div class="nameClass">
						<span>{{ item.creator.title }} </span><span>{{ item.createTime }}</span>
					</div>
					<p>{{ item.description }}</p>
					<div class="resultList-item-content-evaluate">
						<div class="evaluateItem">
							<p>服务态度</p>
							<div class="stars">
								<img v-for="(item, index) in item.attitudeScore" :key="index" src="@/assets/imgs/checkStar.png" alt="" />
								<img v-for="(item, index) in 5 - item.attitudeScore" :key="index + 10" src="@/assets/imgs/nocheckStar.png" alt="" />
							</div>
							<p>
								{{
									item.attitudeScore == 5
										? '超棒'
										: item.attitudeScore == 4
										? '满意'
										: item.attitudeScore == 3
										? '还行'
										: item.attitudeScore == 2
										? '一般'
										: '糟糕'
								}}
							</p>
						</div>
						<div class="evaluateItem">
							<p>服务效率</p>
							<div class="stars">
								<img v-for="(item, index) in item.efficiencyScore" :key="index" src="@/assets/imgs/checkStar.png" alt="" />
								<img v-for="(item, index) in 5 - item.efficiencyScore" :key="index + 10" src="@/assets/imgs/nocheckStar.png" alt="" />
							</div>
							<p>
								{{
									item.efficiencyScore == 5
										? '超棒'
										: item.efficiencyScore == 4
										? '满意'
										: item.efficiencyScore == 3
										? '还行'
										: item.efficiencyScore == 2
										? '一般'
										: '糟糕'
								}}
							</p>
						</div>
						<div class="evaluateItem">
							<p>沟通能力</p>
							<div class="stars">
								<img v-for="(item, index) in item.communicationScore" :key="index" src="@/assets/imgs/checkStar.png" alt="" />
								<img v-for="(item, index) in 5 - item.communicationScore" :key="index + 10" src="@/assets/imgs/nocheckStar.png" alt="" />
							</div>
							<p>
								{{
									item.communicationScore == 5
										? '超棒'
										: item.communicationScore == 4
										? '满意'
										: item.communicationScore == 3
										? '还行'
										: item.communicationScore == 2
										? '一般'
										: '糟糕'
								}}
							</p>
						</div>
						<div class="evaluateItem">
							<p>服务满意度</p>
							<div class="stars">
								<img v-for="(item, index) in item.satisfactionScore" :key="index" src="@/assets/imgs/checkStar.png" alt="" />
								<img v-for="(item, index) in 5 - item.satisfactionScore" :key="index + 10" src="@/assets/imgs/nocheckStar.png" alt="" />
							</div>
							<p>
								{{
									item.satisfactionScore == 5
										? '超棒'
										: item.satisfactionScore == 4
										? '满意'
										: item.satisfactionScore == 3
										? '还行'
										: item.satisfactionScore == 2
										? '一般'
										: '糟糕'
								}}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="nullData" v-else>暂无数据</div>
		<span slot="footer" class="dialog-footer">
			<el-button @click="createdVisible = false">关 闭</el-button>
		</span>
	</el-dialog>
</template>

<script>
export default {
	components: {},
	data() {
		return {
			createdVisible: false,
			evaluationList: [],
		};
	},
	watch: {},
	created() {},
	methods: {
		init(data) {
			this.createdVisible = true;
			this.$http
				.get(this.api['ServiceEvaluation#index'].href, {
					params: { size: 9999, current: 1, serviceEvaluationId: data.row.id, discriminationCode: data.discriminationCode },
				})
				.then((res) => {
					if (res.data && res.data.success) {
						this.evaluationList = res.data.collection || [];
						console.log(this.evaluationList, '评价列表');
					}
				})
				.catch((e) => {});
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}
	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
}
.resultList {
	border: 1px solid rgba(0, 0, 0, 0.06);
	border-radius: 6px;
	padding: 16px;

	&-item {
		border-bottom: 1px solid rgba(0, 0, 0, 0.06);
		&:last-child {
			border: none;
		}
		img {
			width: 32px;
			height: 32px;
		}
		&-content {
			width: calc(100% - 44px);
			margin-left: 12px;
			.nameClass {
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: 14px;
				line-height: 14px;
				height: 14px;
				color: #999;
				margin-bottom: 10px;
				span:first-child {
					color: #333;
				}
			}
			p {
				font-size: 14px;
				line-height: 20px;
				color: #333;
				margin-bottom: 12px;
			}
			&-evaluate {
				background-color: #f9f9f9;
				padding: 20px 16px;
				width: 320px;
				margin-bottom: 16px;
				.evaluateItem {
					display: flex;
					justify-content: space-between;
					align-items: center;
					justify-items: center;
					margin-bottom: 20px;
					&:last-child {
						margin-bottom: 0;
					}
					p {
						margin-bottom: 0;
						font-size: 12px;
						line-height: 12px;
						height: 12px;
						gap: 14px;
						&:first-child {
							color: #666;
							width: 60px;
							text-align: end;
						}
						&:last-child {
							color: #c5c5c5;
						}
					}
					.stars {
						display: flex;
						align-items: center;
						img {
							width: 16px;
							height: 16px;
							margin-right: 24px;
							&:last-child {
								margin-right: 0;
							}
						}
					}
				}
			}
		}
	}
}
/deep/ .el-dialog {
	.el-dialog__title {
		margin-bottom: 25px;
	}
}
/deep/ .el-textarea__inner {
	min-height: 64px !important;
	line-height: 18px !important;
	padding: 5px 12px !important;
}
/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .w50 {
	width: calc(50% - 8px);
	display: inline-block;
}
.nullData {
	text-align: center;
	color: #999999;
	font-size: 18px;
	margin-top: 20px;
	margin-bottom: 20px;
}
</style>